import React from "react";
import Helmet from "react-helmet";
import { Container, Header, Image } from "semantic-ui-react";
import Layout from "../components/Layout";

const displayDate = (i) => {
  const d = new Date(i);
  return d.toLocaleDateString("en", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
};

const Profile = ({ pageContext }) => {
  return (
    <Layout page={pageContext.slug} isMember>
      <Helmet
        title={pageContext.name}
        meta={[
          {
            name: `description`,
            content: `${pageContext.name} joined the 27 club on ${displayDate(pageContext.died)}`,
          },
        ]}
      />
      <Container text>
        <div className="mt5">
          <Header as="h1" dividing>
            {pageContext.name}
            <Header.Subheader>{pageContext.short}</Header.Subheader>
          </Header>
          {pageContext.image ? <Image alt={pageContext.name} src={`/${pageContext.image}`} style={{maxWidth: '100%', maxHeight: '400px'}} /> : null}
          <p className="pt3">
            {pageContext.name} joined the 27 club on{" "}
            {displayDate(pageContext.died)}. The cause of death is recorded as:{" "}
            <i>{pageContext.cause}</i>
          </p>
          <div dangerouslySetInnerHTML={{ __html: pageContext.copy}} />
        </div>
      </Container>
    </Layout>
  );
};

export default Profile;
